/*Lines2 */

.line-container-2 {
  width: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;



}

.line-2 {
  justify-content: center;
  display: flex;
  border: none;
  border-top: 3px solid black;
  color: black;
  width: 100%;

}

.thin-2 {
  border: 1px solid black;
  margin: 5px auto 0;
  width: 100%;
}

.line-dotted-2 {
  border: 1px dotted black;
  margin: 5px auto 0;
  width: 100%;
}