.container-bubbles-2 {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1.5fr;
  grid-template-rows: 1fr 0.7fr 1.5fr;
  width: 1100px;
  margin: -5em 15% 0;
  height: 600px;
  position: relative;
  z-index: +3;
  font-family: var(--h1-li-font-enriqueta);
}

.courses-2 {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: rgb(221, 55, 130);
  text-align: center;
  text-transform: uppercase;
  color: white;
  margin: 1rem 0;
  display: flex;
  justify-content: center;

}

.courses-2 p:last-child {
  text-transform: none;
  font-size: 25px;
  margin: 1rem auto;
  background-color: white;
  color: rgb(221, 55, 130);
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  border-radius: 20px;
  border: 1px rgb(221, 55, 130) solid;

}

.courses-2 p {
  font-size: 18px;
}

.courses-p-text {
  margin-top: 15px;
}

.courses p {
  margin: 5px 5px 1px;
}

.courses-text {
  position: relative;
  top: 30%;
}

.courses-text h2 {
  font-size: 32px;
  margin-top: 10px;
  line-height: 1.2em;
  font-weight: 500;
}


.courses-2 .bubble-dotted {
  margin-top: 10px;
  margin-bottom: 10px;
}

.courses-2 .first-line,
.courses-2 .second-line,
.courses-2 .bubble-dotted {
  width: 350px;
}



.item {
  display: flex;
  justify-content: center;
  align-items: center;
}



.sign-up-2 {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background-color: rgba(112, 175, 24, 1);
  grid-row: span 2;
  align-self: end;
  align-items: flex-start;


}

.sign-up-text {
  position: absolute;
  text-transform: none;
  color: white;


}


.sign-up-text h2 {
  font-size: 60px;
  margin-top: 3rem;
  text-transform: uppercase;
}

.sign-up-text p {
  margin-top: 1rem;
  font-size: 25px;
}

.kontakt-2 {
  font-size: 30px;
  text-transform: uppercase;
  margin-right: 8rem;
  transform: translate(-200px, -50px);

}

.kontakt-2 .contact-us a {
  text-decoration: none;
  color: inherit;
  /* Use the inherited color */
}

.sign-up-text a {
  color: white;
  text-decoration: none;
  font-size: 25px;
  margin-top: 1rem;
}



.kontakt-2 hr {
  width: 250px;
  text-align: center;
  border: 1px rgba(black, black, black, 0.5);
  margin-top: 0.5rem;
  justify-content: center;

}

.contact-us {
  cursor: pointer;
}



.find-us-2 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: black;
  align-self: flex-start;


}

.find-us-2 p a {
  text-transform: uppercase;
  text-decoration: none;
  color: white;


}




@media screen and (min-width: 1400px) {

  .container-bubbles-2 {
    width: 70%;
    margin: -5em 18% 0;
  }

}

@media screen and (min-width: 1680px) {

  .container-bubbles-2 {
    width: 70%;
    margin: -5em 25% 0;
  }

}

@media screen and (min-width: 3500px) {

  .container-bubbles-2 {
    width: 37%;
    margin: -5em 37% 0;
  }

}


@media screen and (max-width: 1250px) {
  .container-bubbles-2 {
    margin: -5em 10% 0;
  }
}


@media screen and (max-width: 1110px) {
  /*tuto zacinaju columns */

  .container-bubbles-2 {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    width: 100%;

  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sign-up-2 {
    align-self: flex-start;
    margin-left: 4rem;
    margin-bottom: 2rem;
  }

  .sign-up-text h2 {
    margin-top: 0rem;
    text-transform: uppercase;
  }

  .sign-up-text p {
    margin-top: 1rem;
    font-size: 1.5rem;
  }

  .find-us-2 {
    align-self: flex-end;
    transform: translate(-200px, -65px);
    margin-bottom: 10px;
  }

  .kontakt-2 {
    transform: none;
  }


}


@media screen and (max-width: 920px) {

  .sign-up-2 {
    align-self: inherit;
    margin: 0;
  }

  .kontakt-2 {
    margin: 2rem 0;
  }


  .find-us-2 {
    align-self: center;
    transform: none;

  }

}

@media screen and (max-width: 500px) {

  .courses-2 {


    width: 350px;
    height: 350px;



  }

  .courses-2 h2 {
    font-size: 28px;
  }

  .courses-2 p:last-child {
    font-size: 23px;
  }

  .courses-2 .first-line,
  .courses-2 .second-line,
  .courses-2 .bubble-dotted {
    width: 300px;
  }
}

@media screen and (max-width: 370px) {

  .courses-2 {


    width: 250px;
    height: 250px;


  }



  .courses-p-text {
    margin-top: 0px;



  }

  .courses p {
    margin: 5px 5px 1px;
  }

  .courses-text {
    position: relative;
    top: 10%;
  }

  .courses-text h2 {
    font-size: 25px;
    margin-top: 1px;

  }

  .courses-2 p:last-child {
    text-transform: none;
    font-size: 18px;
    background-color: white;
    color: rgb(221, 55, 130);
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    border-radius: 20px;
    border: 1px rgb(221, 55, 130) solid;

    margin: 0.5rem auto;

  }

  .courses-2 .first-line,
  .courses-2 .second-line {
    width: 130px;

  }

  .courses-2 .second-line {
    margin-bottom: 5px;
  }

  .courses-2 .bubble-dotted {
    margin: 5px auto;
    width: 230px;
  }

  .sign-up-2 {
    width: 250px;
    height: 250px;
  }

  .sign-up-text h2 {
    font-size: 25px;
  }

  .sign-up-text p {
    margin: 1rem auto;
    font-size: 18px;

  }


}