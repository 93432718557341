*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.carousel{
    padding: 2rem;
    background-color: white;
    color: pink;
    max-width: 600px;
    width: 100%;
    height: 100%;
    position: relative;
    

}

.slick-slider{
    cursor: pointer;
}


.btn-prev{
    top:40%;
    left:-15px;
    position: absolute;
    border: none;
    cursor: pointer;
    

}

.carousel button{
    background-color: transparent;
}

.btn-next{
    right: -10px;
    top: 40%;
    transform: rotate(180deg);
    position: absolute;
    border: none;
    cursor: pointer;
    
}

/* .box{
    background-color: lightblue;
    height: 200px;
    border-radius: 50%;
}

.box h3{
    text-align: center;
} */

/* .slick-slide div{
    margin: 0 1rem;


} */


.slick-arrow.slick-prev, .slick-arrow.slick-next{
    background: black;
    color: black;
    border-radius: 50%;
    visibility: hidden;
    
}

.slick-arrow.slick-prev:hover, .slick-arrow.slick-next:hover{
    background: black;
}

.box{
    /* position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%; */
  display: -webkit-flex;
  display: flex;
  text-align: center;
  justify-content: space-between;
  width: 600px;
}


.box img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 15px;
     
  
}


@media screen and (max-width: 1110px){

    .small-slider{
        margin: 0 auto;
    }

    .box img{
        width: 180px;
        height: 180px;
        
    }
}

@media screen and (max-width: 800px){

    .carousel{
        width: 550px;
        padding: 0;
        margin: 0 auto;
    }

    .box img{
        width: 180px;
        height: 180px;
        
    }
}
@media screen and (max-width: 650px){

    .carousel{
        width: 400px;
        padding: 0;
        margin: 5px auto;
    }

    .box img{
        width: 180px;
        height: 180px;
        
    }

    .btn-next{
        right: -30px;
    }
    .btn-prev{
        left: -30px;
    }
}


@media screen and (max-width: 470px){
    .box{
        
        height: auto;
        justify-content: center;
        align-items: center;
    }


    .box img{
        width: 180px;
        height: 180px;
        padding: 0;
        align-self: center;
        
    }

    .carousel{
        padding: 0;
        margin: 0;
        width: 300px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .slick-list{
        width: auto;
    }
   
    .slick-list img{
        
        margin: 0 auto;
    }

    @media screen and (max-width: 380px){

       

        .btn-next{
            right:  15px;
        }
        .btn-prev{
            left: 5px;
            z-index: 1;

        }

    }
    @media screen and (max-width: 280px){

       .carousel{
        width: 200px;
        height: 100px;
        
        padding: 0;
        margin: 0;
       }

       .box img{
        width: 120px;
        height: 120px;
        padding: 0;
       }

        .btn-next{
            right:5px;
            top: 50%;   
        }
        .btn-prev{
            left: 5px;
            top: 50%;
            z-index: 1;

        }

    }

}



