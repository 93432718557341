.find-us-at-container {
    width: 1000px;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
}

.our-location-content {
    display: flex;

}

.our-location-left {
    display: flex;
    width: 35%;
    flex-direction: column;
}

.our-location-bubble {
    display: inline-flex;
    align-items: center;
    min-width: 5em;
    background-color: black;
    border-radius: 50%;
    justify-content: center;
}

.our-location-bubble:after {
    content: '';
    padding-top: 100%;
    display: table;
}

.our-location-bubble-text {

    text-align: center;
    display: flex;
    flex-direction: column;


}

.our-location-bubble-text span {
    color: white;
    font-size: 35px;
    margin-bottom: -25px;
    cursor: pointer;
}

.our-location-bubble-text a {
    text-decoration: none;
    color: white;
}

.our-location-bubble .first-line {
    width: 16vw;
    margin: 0 auto;


}

.our-location-bubble .second-line {
    width: 16vw;
    margin: 5px auto;

}

.our-location-bubble .bubble-dotted {
    width: 20vw;
    margin: 2px 0;

}

.our-location-bubble-text h2 {
    color: white;
    font-family: var(--h1-li-font-enriqueta);
    font-size: 4vw;
    line-height: 1.1em;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-flex;
    text-align: center;

}

/*-----------------*/

.reasons-to-learn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.reasons-to-learn .small-circle {
    position: relative;

}

.reasons-to-learn .left-text p {
    margin: 1rem 0;

}

.reasons-left {
    display: flex;
    flex-direction: column;


}



.circle-and-header-reasons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem;

}

.circle-and-header-reasons h3 {
    font-family: var(--h1-li-font-enriqueta);
    font-size: 18px;
    line-height: 1.3em;
    margin: 1rem;
    text-transform: uppercase;

}

.text-and-line {
    margin: .5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.bus {
    margin: 0 5px 5px;
    padding: 0 5px 5px;
}

.text-and-line p {
    margin: 1rem 0;
    font-size: 14px;
    line-height: 1.6em;
    font-family: var(--p-font);

}

/*prava strana */

.kde-sme-right {
    position: relative;
    width: 100%;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
}

.kde-sme-right h2 {
    font-family: var(--h1-li-font-enriqueta);
    font-size: 63px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.3em;

}

.kde-sme-right .option-headline {
    position: relative;
}

.headline-box .option-headline {
    position: relative;

}

.small-circle-kde {
    background-color: #DD3782;
    z-index: -1;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    transform: translate(-15px, -15px);
}

.kde-sme-right .headline-box {
    margin: 1rem auto;
}

.our-location-text p {
    font-family: var(--p-font);
    font-size: 14px;
    line-height: 1.6em;
    width: 100%;

}

.google-map-box {

    overflow: hidden;

    padding-bottom: 56.25%;

    position: relative;

    height: 0;

}

.google-map-box iframe {

    left: 0;

    top: 0;

    height: 100%;

    width: 100%;

    position: absolute;

}

.green-kde {
    background-color: #70AF18;
}

.headline-box.objednanie {
    margin-top: 4rem;

}

.bubbles-box {
    display: flex;
    flex-direction: row;
    height: 400px;
    justify-content: center;

}

.objednanie-bubbles-container h3 {
    font-family: var(--josefin-font);
    line-height: 1.3em;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;

}

.objednanie-bubbles-container a {
    text-decoration: none;
    color: inherit;
}

.green-bubble {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #70AF18;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.green-bubble p {
    font-size: 32px;
    line-height: 1.2em;
    font-family: var(--josefin-font);
    text-transform: uppercase;
    text-align: justify;
    margin-top: 5px;

}



.green-bubble p:last-child {
    text-transform: none;
    font-weight: bold;
    font-size: 24px;
    margin-top: 10px;
}

.bubble-img {
    align-self: flex-end;
}

.bubble-img img {
    width: 300px;
    height: 300px;
    border-radius: 50%;


}

.reserve-term-text {
    margin: 1rem 0;
    font-family: var(--p-font);
    line-height: 1.6em;
    font-size: 14px;
}

.green-span {
    color: #70AF18;
}

.pink-span {
    color: #DD3782;
}

/* BREAKPOINTS */

@media screen and (min-width: 1700px) {
    .our-location-bubble-text h2 {
        font-size: 60px;
    }

    .our-location-bubble .first-line {
        width: 250px;
        margin: 0 auto;


    }

    .our-location-bubble .second-line {
        width: 250px;
        margin: 5px auto;

    }

    .our-location-bubble .bubble-dotted {
        width: 300px;
        margin: 2px 0;

    }

}

@media screen and (max-width: 1110px) {



    .our-location-left {
        width: 80%;


    }

    .our-location-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .our-location-text p,
    .objednanie-term-text p {

        width: 70%;
        margin: 1rem auto;

    }

    .our-location-bubble {
        max-width: 22em;
        align-self: center;

    }

    .our-location-bubble .first-line {
        width: 230px;
        margin: 0 auto;


    }

    .our-location-bubble .second-line {
        width: 230px;
        margin: 5px auto;

    }

    .our-location-bubble .bubble-dotted {
        width: 250px;
        margin: 2px 0;

    }

    .our-location-bubble-text h2 {
        font-size: 30px;
        margin: 0 auto;

    }

    .bubbles-box {
        flex-direction: column;
        height: auto;
    }

    .bubble-img {
        align-self: center;
    }

    .our-location-bubble-text span {

        position: relative;
        top: 35px;
        font-size: 40px;
    }


}

@media screen and (max-width: 768px) {

    .our-location-bubble-text h2 {
        font-size: 30px;
    }

    .small-circle-kde {
        left: 20%;


    }

    .option-headline h2 {
        font-size: 52px;
    }

    .green-kde {
        left: 16%;
    }

    .our-location-bubble .first-line {
        width: 210px;
        margin: 0 auto;


    }

    .our-location-bubble .second-line {
        width: 210px;
        margin: 5px auto;

    }

    .our-location-bubble .bubble-dotted {
        width: 230px;
        margin: 2px 0;

    }
}

@media screen and (max-width: 600px) {

    .our-location-left {
        width: 90%;
    }

    .our-location-bubble {
        display: flex;
        align-items: center;
        width: 250px;
        height: 250px;
        background-color: black;
        border-radius: 50%;
        justify-content: center;
        align-self: center;
    }

    .our-location-bubble:after {
        content: '';
        padding-top: 100%;
        display: table;
    }

    .our-location-bubble-text {

        text-align: center;
        display: flex;
        flex-direction: column;


    }

    .our-location-bubble .first-line {
        width: 230px;
        margin: 0 auto;


    }

    .our-location-bubble .second-line {
        width: 230px;
        margin: 5px auto;

    }

    .our-location-bubble .bubble-dotted {
        width: 230px;
        margin: 2px 0;

    }

    .our-location-bubble-text h2 {
        color: white;
        font-family: var(--h1-li-font-enriqueta);
        font-size: 30px;
        line-height: 1.1em;
        font-weight: 400;
        text-transform: uppercase;
        display: inline-flex;
        text-align: center;

    }

    .green-bubble,
    .bubble-img img {
        width: 250px;
        height: 250px;
        margin: 1rem auto;


    }

    .green-bubble p {
        font-size: 25px;

    }

    .green-bubble p:last-of-type {
        font-size: 20px;
    }

    .option-headline h2 {
        font-size: 35px;
    }

    .small-circle-kde {
        left: 23%;
        height: 80px;
        width: 80px;
        border-radius: 50%;


    }

    .green-kde {
        left: 20%;
    }




}

@media screen and (max-width: 480px) {
    .small-circle-kde {
        left: 18%;
    }

    .green-kde {
        left: 13%;
    }
}

@media screen and (max-width: 415px) {
    .small-circle-kde {
        left: 12%;
    }

    .green-kde {
        left: 8%;
    }
}

@media screen and (max-width: 320px) {
    .small-circle-kde {
        left: 25%;
        top: -8%;
    }

    .green-kde {
        left: 30%;
    }
}