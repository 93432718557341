*[name="message"] {
    background-color: black;
    color: white;
    font-family: var(--p-font);
    display: block;
    margin: 10px auto;

}

*[name="message"]:hover {
    background-color: var(--pinkcolor);
    ;
}

form input {
    width: 350px;
    height: 30px;
}

textarea {
    width: 350px;
    padding: 5px;
}

.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;


}

#questions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.submit-btn {
    background-color: var(--pinkcolor);
    width: 125px;
    height: 30px;
    border: none;
    color: white;
    font-size: 17px;
    align-self: flex-end;
    /* margin-left: 220px; */




}

.input {
    display: flex;
    flex-direction: column;

}

.submit-btn:hover {
    background-color: black;
    color: var(--pinkcolor);
}


.msg {
    visibility: hidden;
    background-color: rgba(112, 175, 24, 1);
    width: 250px;
    text-align: center;
    font-family: var(--p-font);
}

/* .notSent{
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.2);  
    font-family:'Times New Roman', Times, serif;
    width: 250px;
    text-align: center;
    padding: 15px;
    margin: 1rem;
    background-color: aquamarine;
} */

.msgAppear {
    visibility: unset;
    font-weight: 600;
    margin: 5px;
    padding: 5px;
    background-color: rgba(112, 175, 24, 1);
    height: 30px;


}

.error {
    color: red;
    padding: 0px;
    margin-bottom: -10px;
    text-align: start;
    font-size: 12;

}

.error-msg {
    width: 380px;
    height: 30px;
    background-color: rgba(221, 55, 130, .5);
    text-align: center;
    padding: 5px;
    font-family: var(--p-font);
    margin: 5px;
    font-weight: 600;
}

::placeholder {
    color: white;
}

label {
    text-align: start;
}

/* span{
    font-size: 12px;
    color: red;
    padding:  3px;
    display: none;
} */

input:invalid[focused="true"]~span {
    display: block;
}


@media screen and (max-width: 380px) {

    form input {
        width: 250px;
        height: 30px;
    }

    textarea {
        width: 250px;
        padding: 5px;
    }

}