.eng-children-container {
    width: 1000px;
    margin: 0 auto;
    position: relative;
}

.eng-children-container .headline-box {
    margin: 4em auto;
}

.card-text-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-text {
    font-family: var(--p-font);
    font-size: 14px;
    line-height: 1.6em;
    width: 700px;
    margin: 1rem;
    padding: 1rem;
    text-align: justify;
    box-shadow: 2px 2px 15px 3px rgba(0, 0, 0, 0.2);
}

.right {
    align-self: flex-end;
}

.card-text span {
    font-weight: 600;
    font-size: 18px;
}

.det-kurz {
    color: var(--pinkcolor);
}

.card-text-cont {
    border-bottom: 1px dotted black;
    margin-bottom: 1rem;
}

.test-img {
    width: 250px;


}

@media screen and (max-width: 1110px) {
    .eng-children-container {
        width: 100%;
    }

    .card-text-cont {
        align-items: center;
    }

    .card-text {
        width: 80%;
    }

    .right {
        align-self: center;
    }

}

@media screen and (max-width: 350px) {
    .eng-children-container .option-headline {
        font-size: 35px;
    }

    .course-card {

        width: 85%;

    }

    .course-card:hover {
        scale: 1;


    }

    .card-headline {
        font-size: 25px;
    }

    .img-card img {
        width: 200px;

    }

}