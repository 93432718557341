
@import url('https://fonts.googleapis.com/css2?family=Enriqueta&family=Josefin+Slab&family=Lobster&family=Montserrat&family=Open+Sans&display=swap');
@import url('//db.onlinewebfonts.com/c/33ca94f43a53fc634323a6fe191ffd64?family=TrendSansW00-Four');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  

}

:root{
  --p-font: 'Open Sans', sans-serif;
  --h1-li-font-enriqueta: 'Enriqueta', 'serif';
  --josefin-font: 'Josefin Slab', 'serif';
  --trend-sans: 'TrendSansW00-Four';
  --pinkcolor: #DD3782;
  --greencolor: #6EAE1A;
  --greencolor2: #70AF18;
  
}
.App {
  text-align: center;
}

.hidden{
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
