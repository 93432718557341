/* NAVBAR */

header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: 0 2rem;
  position: relative;



}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  /* margin: 0 auto; */
  /* position: relative; */


}


nav li {
  display: inline;
  text-align: center;
  margin-left: 15px;
  margin-top: 6em;
  text-transform: uppercase;


}


li a:hover {
  color: rgb(221, 55, 130);
  transition: color 0.2s ease-in-out 0.1s;
}


li a {
  color: black;
}

.home-white {
  /* background-color: white; */
  padding: 0.2em 1em;

}

.home {
  background-color: rgb(221, 55, 130);
  padding: 0.2em 1em;
  color: white;

}

.home a {
  color: white;
}

.home a:hover {
  color: black;
}

.home:hover {
  color: black;
}



.logo {
  width: 12em;
  height: 8em;
  background-color: black;
  border-radius: 50%;
  position: relative;
  /* top: -15px;
    left: 15px; */

}

.logo h1 {
  color: white;
  font-family: 'Lobster', cursive;
  font-size: 2.5em;
  line-height: 0.7rem;
  text-align: center;
  padding: 35% 0;
  text-align: center;
  margin: 0;
  font-weight: 400;

}

.logo a {
  color: white;
  text-decoration: none;
}

/* .bubble {
  position: absolute;
  top: 0.5px;
  right: 250px;
  transform: scaleX(1);
  /* z-index: -1; */


.bubble {
  position: absolute;
  top: 0.5px;
  right: 250px;


}

.nav-btn {
  cursor: pointer;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
  color: black;
  font-size: 2em;
}

ul>hr {
  display: none;
}

.dropdown-span {
  display: none;
}

ul.ul-list {
  position: relative;
}


@media screen and (min-width: 1700px) {


  .bubble {
    right: 550px;
  }

}

@media screen and (min-width: 3000px) {


  .bubble {
    right: 1300px;
  }

}


@media screen and (max-width: 1200px) {

  header {
    width: 1200px;
  }

  nav {
    width: 100%;
  }

}

@media all and (max-width: 1110px) {

  header {
    justify-content: space-between;
    width: 100%;

  }

  .nav-btn {
    visibility: visible;
    opacity: 1;
  }


  header nav {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: black;
    transition: 1s;
    transform: translateY(-180vh);
    z-index: 20;



    /* background-color: #0D2538;
      position: fixed;
      transform: translateY(-100vh);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      min-height: 100vh;
      min-width: 100%;
      padding-top: 3.5rem;
      transition: 0.3s ease-in-out; */

  }

  .mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: black;
    transition: 1s;
    transform: translateY(-180vh);
    z-index: 20;

  }


  ul {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  nav li {
    margin-top: 2em;
    font-size: 1em;
    border-bottom: 1px dotted white;
    padding-bottom: 1em;


  }



  nav li:last-of-type {
    border-bottom: none;
  }

  nav li a {
    color: white;


  }

  /* ul > hr{
    border-bottom:  1px dotted white ;
    width: 100%;
    display: inherit;
    margin: 1em auto ;
    text-align: center;
  
  
  } */




  .responsive_nav {
    transform: none;
  }

  .bubble {
    top: 0em;
    right: 0em;
  }

  .nav-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
  }

  .dropdown-span {
    display: flex;
    color: white;
    font-size: 25px;
    padding: 0;
    margin: 0;
    justify-content: center;



  }





}




@media all and (max-width: 500px) {


  body {
    background-color: white;
  }

  header {
    justify-content: space-between;

  }

  /* li{
      flex-direction: column;
    } */

  .logo {

    margin-left: -2rem;
    top: 0;
    left: 0;
    width: 9em;
    height: 5em;

  }

  .logo h1 {
    font-size: 2em;
    padding: 25% 0;
  }

  /* .bubble{
      top: 5em;
      right: 10em;
      background-color: transparent;
      width: 8%;
  
    } */
}

@media all and (max-width: 380px) {
  .bubble {
    top: 5.5em;


  }

}



/*Burger2*/

/* 
  @media screen and (max-width: 768px){
  ul{
      display: flex;
      flex-flow: column nowrap;
      height: 100vh;
      width: 100%;
      list-style: none;
      width: 100%;
      position: fixed;
      
      
  }
    
    .nav{
      
      height: 100%;
      background-color: black;
      
     
    
    }
  
    .nav li a{
      color:white;
    }
  
     
  } */