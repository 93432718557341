/*
FOOTER

*/

.footer-container {
  width: 100%;
  background-color: black;
  height: auto;
  display: flex;
  flex-wrap: wrap;



}

.footer-container-small {
  width: 1100px;
  margin: 0 auto;
  padding: 1em;
  display: flex;
  flex-wrap: wrap;


}


.footer-box {
  width: 260px;
  font-family: 'Open Sans', sans-serif;
  margin: 1em auto;
  height: 250px;
  text-align: left;
  padding: 1em;


}

.footer-box-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  min-height: 70px;
  min-width: 260px;

}

.footer-box:nth-child(5) .footer-box-text,
.footer-box:nth-child(6) .footer-box-text {
  align-items: flex-start;
  margin: 24% 0;

}

.footer-box h4 {
  color: rgb(146, 146, 146);
  margin: 1em 0;
  text-transform: uppercase;
  font-size: 1em;
}

.footer-box p {
  color: white;
  line-height: 1.5em;
  font-size: 14px;

}

.footer-tel {
  text-decoration: none;
  color: #dd3782;

}

.footer-tel:hover {
  color: #f77eb6;
  text-decoration: underline;
}

.footer-box p:first-of-type {
  margin-top: 1em;
}

.footer-mail {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.footer-mail:hover {
  text-decoration: underline;
}

.footer-box hr {
  width: 90%;

  color: rgb(207, 207, 207);
  border-top: 1px dotted rgb(207, 207, 207);

}

.footer-p input {
  width: 99%;
  padding: 0.5em;
  margin-top: 1em;

}

.btn-subscribe {
  background-color: #dd3782;
  color: white;
  margin: 1em 0 0 9em;
  padding: 0.5em 1em;
  display: block;



}

.btn-subscribe:hover {
  color: black;
  cursor: pointer;
  transition: ease-in-out 0.3s;

}

.subscribe-form {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.subscribe-form .msg {
  width: 230px;
  border-radius: 5px;
  margin-top: 15px;
  display: none;
}

.subscribe-form .msg.msgAppear {
  height: 70px;
  display: block;

}

.subscribe-form .error-msg {
  width: 230px;
  height: 50px;
  margin-top: 15px;
  border-radius: 5px;
  background-color: rgba(221, 55, 130, 1);

}

.gdpr {
  margin-top: 3px;
  font-size: 12px;



}

.gdpr a {
  text-decoration: none;
  color: white;
}

.gdpr a:hover {
  color: var(--pinkcolor);
}


@media screen and (max-width: 555px) {
  .footer-box {
    width: 350px;
    height: 200px;
    margin: 0 auto;
  }

  .subscribe-form {
    align-items: center;

  }


  .subscribe-form .error-msg {
    width: 320px;

  }

  .btn-subscribe {
    width: 100px;
    align-self: flex-end;
  }

  .footer-box:nth-child(3) {
    margin-bottom: 50px;
  }

  .subscribe-form .msg {}

  .subscribe-form .msgAppear {
    height: 50px;
  }


  .subscribe-form .msg {
    width: 320px;
  }

  .footer-box:nth-child(4) {
    margin-top: 50px;
  }

}



@media screen and (max-width: 400px) {
  .footer-box {
    width: 250px;
    height: 200px;
    margin: 0 auto;
  }

  .subscribe-form .error-msg {
    width: 220px;
  }

  .subscribe-form .msg {
    width: 220px;

  }

  .subscribe-form .msgAppear {
    height: 70px;
  }

  .footer-box:nth-child(4) {
    margin-top: 70px;
  }

}


@media screen and (max-width: 290px) {

  .footer-container {
    width: 100%;
  }

  .footer-box {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin: auto;

  }

  .footer-box-text {
    min-width: unset;
    height: auto;
    margin: auto;
  }

  .subscribe-form .error-msg {
    width: 170px;
    font-size: 15px;

  }

  .footer-box:nth-child(3) {
    margin: 0 auto;
  }

  .subscribe-form .msg {

    width: 170px;
    font-size: 13.5px;

  }

  .subscribe-form .msgAppear {
    height: 70px;
  }

}