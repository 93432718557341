.business-courses-container {
    width: 1000px;
    margin: 0 auto;
    position: relative;

}

.headline-box .option-headline {
    width: 85%;
    margin: 0 auto;
}

.headline-box .line-container {
    width: 85%;
    margin: 0 auto;
}

.business-courses-bubbles-box {
    display: flex;
    width: 1000px;
    margin: 0 auto;
    position: relative;
    align-items: center;
    justify-content: center;


}

.black-bubble {
    display: flex;
    width: 55%;
    justify-content: flex-end;
    align-items: center;


}


.black-bubble-box {
    width: 350px;
    height: 350px;
    background-color: black;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;






}

.black-bubble .first-line {
    width: 300px;
    margin: 0;


}

.black-bubble .second-line {
    width: 300px;
    margin: 5px 0;

}

.black-bubble .bubble-dotted {
    width: 300px;
    margin: 2px 0;

}

.black-bubble-box h2 {
    color: white;
    font-family: var(--h1-li-font-enriqueta);
    font-size: 65px;
    line-height: 1.1em;

}

.light-bulb-box {
    width: 600px;
    flex: 0 0 500px;
    transform: translateX(-100px);
    z-index: -1;

}

.light-bulb-box img {
    width: 100%;
    border-radius: 50%;
}

/* Firemne kurzy spodok */

.business-courses-text {
    margin: 3em auto;
    width: 95%;

}

.business-courses-text p:first-of-type {
    font-family: var(--h1-li-font-enriqueta);
    line-height: 1.5em;
    font-size: 18px;
    margin: 1em 0;
}

.business-courses-text p:nth-of-type(2) {
    font-family: var(--p-font);
    line-height: 1.6em;
    font-size: 14px;
    margin: 1em 0;
}

.kurz-na-mieru {
    margin: 0 auto;
}

.kurz-na-mieru h2 {
    font-size: 63px;
    line-height: 1.1em;
    font-family: var(--josefin-font);
    font-weight: 700;
}

.na-mieru-container {
    display: flex;



}

.na-mieru-left {
    display: flex;
    flex-direction: column;
    width: 95%;

}

/* .small-slider{
    width: 600px;
    flex: 0 0 200px;
} */

/* .small-slider img{
    width: 100%;

} */

.course-levels {
    display: flex;
    flex-wrap: wrap;

}

.level {
    width: 48%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;



}



.level-line-p {
    margin: 1rem auto;

}

.level-line-p p {
    margin: 1rem auto;
}

.level h4 {
    font-family: var(--h1-li-font-enriqueta);
    font-size: 18px;
    line-height: 1.3em;
    margin: 1rem auto;
    text-align: center;
}

.na-mieru-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.na-mieru-right .small-circle {
    position: relative;

}

.na-mieru-right .left-text p {
    margin: 1rem 0;

}




/*Pravy StplecJS*/

.right-textbox {
    display: flex;
    flex-direction: column;


}

.na-mieru-right h3 {
    font-family: var(--trend-sans);

}

.circle-and-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem;

}

.circle-and-header h3 {
    font-family: var(--h1-li-font-enriqueta);
    font-size: 18px;
    line-height: 1.3em;
    margin: 1rem;

}

.text-and-line {
    margin: .5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.text-and-line:last-of-type {
    border-bottom: none;

}

.text-and-line p {
    margin: 1rem 0;
    font-size: 14px;
    line-height: 1.6em;
    font-family: var(--p-font);

}

@media screen and (max-width: 1110px) {

    .business-courses-container {
        width: 100%;

    }

    .business-courses-bubbles-box {
        flex-direction: column;

    }


    .black-bubble {
        width: 100%;
        justify-content: center;
    }

    .light-bulb-box {
        transform: translate(0, -100px);

    }

    .headline-box {
        width: 80%;
        margin: 4em auto;



    }

    .business-courses-text {
        margin: 0 auto;
        margin-top: -4em;
        width: 80%;
    }

    .kurz-na-mieru {
        display: flex;
        flex-direction: column;
        margin: 3em auto;
        width: 80%;
    }

    .na-mieru-container {

        flex-direction: column;
        width: 100%;
        justify-content: center;


    }

    .na-mieru-right {
        width: 100%;
        margin: 0 auto;

    }

    .na-mieru-left {
        width: 90%;
    }

    .course-levels {
        flex-direction: column;

    }

    .level {
        width: 70%;
    }





}

@media screen and (max-width: 950px) {

    .business-courses-bubbles-box {
        width: 100%;
    }

    .light-bulb-box {
        width: 80%;
    }



}

@media screen and (max-width: 600px) {

    .business-courses-bubbles-box {
        margin: 0 auto;
    }

    .light-bulb-box {
        width: 90%;

    }


    .level {
        width: 80%;
    }

    .level h4 {
        margin: 15px auto;
    }



    .business-courses-text {
        margin-top: -5em auto;


    }

    /* .small-slider{
        width: 400px;
    } */

    .kurz-na-mieru h2 {
        font-size: 40px;
    }
}

@media screen and (max-width: 400px) {

    .black-bubble-box {
        width: 200px;
        height: 200px;
    }

    .black-bubble-box h2 {
        font-size: 25px;
    }

    .black-bubble-box .first-line,
    .black-bubble-box .second-line,
    .black-bubble-box .bubble-dotted {
        width: 190px;
    }

    .light-bulb-box {
        width: 85%
    }

    .business-courses-text {
        margin: -16em auto;
    }

    .kurz-na-mieru {
        margin-top: 17em;
    }

}