.translation-container-druhy {
    max-width: 1000px;
    width: 100%;
    margin: 1rem auto;
    position: relative;
}

/* .headline-box .option-headline{
   
    margin: 0 auto;
}

.headline-box .line-container{
    margin: 0 auto;
} */

.translation-content {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;

}

.translation-left {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;




}

.translation-left .small-circle-translation {
    background-color: #DD3782;
    z-index: -1;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    transform: translate(60px, -40px);

}


/* 
.translation-h2{
    width: 100%;
    margin: 0 auto;
   
} */

.translation-left h2 {
    font-family: var(--h1-li-font-enriqueta);
    font-size: 35px;
    font-weight: 400;
    margin-left: 60px;



}


.translation-left p {
    font-family: var(--p-font);
    font-size: 14px;
    line-height: 1.3em;
    max-width: 520px;
    text-align: justify;
    margin: 15px auto;
    width: 100%;
}


.translation-bubble {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background-color: rgba(112, 175, 24, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;


}

.translation-bubble-text {
    position: absolute;
    text-transform: none;
    color: white;
    font-weight: 400;


}


.translation-bubble-text h2 {
    font-size: 40px;
    line-height: 1.1em;
    font-weight: 400;
    font-family: var(--josefin-font);

}

.translation-bubble-text p {


    font-size: 20px;
    font-family: var(--h1-li-font-enriqueta);
}

.translation-bubble-text p:nth-of-type(3) {
    font-size: 32px;
    line-height: 1.2em;

    font-family: var(--josefin-font);
}

.translation-bubble-text p:nth-of-type(4) {
    font-size: 24px;
    line-height: 1.2em;
    font-family: var(--josefin-font);
}

@media screen and (max-width: 1110px) {

    .translation-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .translation-left {

        /* text-align: center; */
        width: 80%;
        margin: 0 auto;
    }

    .translation-left h2 {
        margin-left: 60px;
    }

    .translation-left p {
        width: 100%;
        max-width: 700px;

    }



    .translation-container-druhy {
        width: 100%;
    }


}

@media screen and (max-width:550px) {

    .translation-left {
        text-align: center;
        margin: 0 auto;
    }

    .translation-left h2 {
        font-size: 30px;
        margin: 0 auto;
    }

    .translation-left .small-circle-translation {
        transform: translate(20px, -40px);
    }

}

@media screen and (max-width: 290px) {
    .translation-bubble {
        width: 250px;
        height: 250px;

    }

    .translation-bubble-text {
        margin-bottom: 3rem;
    }

    .translation-bubble-text h2 {
        font-size: 25px;


    }

    .translation-bubble-text p {
        font-size: 15px;

    }


    #option-headline {
        font-size: 30px;
    }

    .translation-left .small-circle-translation {
        transform: translate(1px, -40px);
    }

}