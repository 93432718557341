.dropdown-menu {
    width: 250px;
    position: absolute;
    left: 7%;
    /* box-shadow: 0 0 2px 2px rgba(221, 55, 130, 0.5); */
    box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
    text-align: center;
    z-index: +5;
    background-color: white;
    margin: 0;
    transition: all 0.4s ease;
    top: 20px;




}

.dropdown-menu li {
    cursor: pointer;
    margin: 0;




}

.dropdown-menu li:hover {
    color: rgb(221, 55, 130);
    /* transition: all 0.4s ease; */
    transition: color 0.2s ease-in-out 0.1s;
}

.dropdown-menu.clicked {
    display: none;

}

.dropdown-link {
    display: block;
    text-decoration: none;
    padding: 3px;
    /* color: black; */
    /*toto mi sposobilo ze ked so hoverovala text nebol biely v dropdowne */
    margin: 2px;
    /* transition: color 0.4s ease-in-out 0.1s; */


}



@media screen and (max-width: 1110px) {
    .dropdown-menu {
        position: relative;
        margin-top: 0;
        left: 0;
        top: 5px;

    }

    .dropdown-menu li {
        margin: 0px;
        padding: 0;
        width: 100%;
    }

    .dropdown-link {
        color: black;
    }



}