.eng-adults-container {
    width: 1000px;
    margin: 0 auto;
    position: relative;
}

.eng-adults-container .headline-box {
    margin: 4em auto;
}

.card-cont {

    display: flex;
    position: relative;
    justify-content: space-between;


}

.right {
    justify-content: flex-end;
}

.course-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 500px;
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);

}

.course-card:hover {
    scale: 1.1;

}


.detailed-title {
    font-family: var(--josefin-font);
    font-size: 32px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: 1px dotted black;
    text-align: center;
}

.card-headline {
    font-size: 39px;
    margin: 1rem;
}

.course-card p {
    font-family: var(--h1-li-font-enriqueta);
    font-size: 18px;
    line-height: 1.5em;
    margin: 1rem;
    text-align: justify;
}

.beginner {
    font-family: var(--p-font);
    font-size: 14px;
    line-height: 1.6em;

}

.course-text {
    font-family: var(--p-font);
    font-size: 20px;
    line-height: 1.6em;
    text-align: justify;
    margin: 1rem;
}

.accordion-container {
    position: relative;
}







@media screen and (max-width: 1110px) {

    .eng-adults-container {
        width: 100%;
    }

    .detailed-title {
        width: 80%;
        text-align: center;
        margin: 0 auto;

    }

    .card-cont {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .course-text {
        width: 80%;
        margin: 1rem auto;
    }


    .card-headline {
        margin: 1rem auto;
    }


}

@media screen and (max-width: 500px) {

    .course-card {
        width: 300px;
    }

    .course-card p {
        text-align: center;
    }

    .card-headline {
        text-align: center;
        font-size: 35px;
    }


}